#root {
  overflow-x: hidden;
}
input, textarea, select {
  font-size: 16px !important;  /* or larger */
    padding-top: 9px !important;
}

input[type="file"] {
  max-width: 100%;
  width: auto; /* Adjust width to be responsive */
}
.pac-container{
  z-index: 1500;
}